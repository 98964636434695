import React from 'react';
import ReactMarkdown from 'react-markdown';

const SectionText = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <section className="py-7 py-lg-12 py-mg-17">
      <div className="container">
        <div className="pl-mg-15">
          <div className="row">
            <div className="col-lg-11 col-xl-9">
              <h2 className="text-hero mb-3">{data.title}</h2>

              <div className="pr-lg-12 pr-xl-20">
                <ReactMarkdown source={data.text} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionText;
