import React from 'react';

//Components
import Intro from '../../components/intro';
import Blocks from '../../components/blocks';
import SectionText from '../../components/section-text';
import SectionFeatures from '../../components/section-features';

const ProcessesPageTemplate = ({ data, cmsPreview, lang }) => {
  return (
    <>
      <Intro data={data.intro} />

      <SectionText data={data.information} />

      <Blocks data={data.blocks} cmsPreview={cmsPreview} lang={lang} />

      <div className="pl-xl-17 mt-7 mt-md-12 mt-lg-17 mt-xl-32">
        <div className="container">
          <h4 className="text-mega">Why should you work with BGSteel?</h4>
        </div>
      </div>

      <SectionFeatures lang={lang} />
    </>
  );
};

export default ProcessesPageTemplate;
