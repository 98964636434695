import React from 'react';
import { graphql } from 'gatsby';

//Components
import Layout from '../components/layout';
import SEO from '../components/seo';

//Template
import ProcessesPageTemplate from '../templates/pages/processes';

const ProcessesPage = props => {
  const blocksData = props.data.allProcessesJson.edges.map(({ node }) => ({
    slug: node.title,
    title: node.languages.en.intro.title,
    entry: node.languages.en.intro.text,
    image: node.languages.en.intro.media,
    hasPage: node.hasPage,
    parent: node.parent,
  }));

  const data = {
    ...props.data.pagesJson.languages.en,
    blocks: [...blocksData],
  };

  return (
    <Layout lang={props.pageContext.langKey}>
      <SEO
        title={data.metaData.title}
        description={data.metaData.description}
      />

      <ProcessesPageTemplate data={data} lang="en" />
    </Layout>
  );
};

export const PROCESSES_PAGE_EN_QUERY = graphql`
  query PROCESSES_PAGE_EN_QUERY {
    pagesJson(pageKey: { eq: "processes" }) {
      languages {
        en {
          metaData {
            title
            description
          }
          intro {
            title
            text
            media {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            thumbnailVideo
            fullVideo
          }
          information {
            text
            title
          }
        }
      }
    }
    allProcessesJson {
      edges {
        node {
          title
          hasPage
          languages {
            en {
              intro {
                media {
                  childImageSharp {
                    fluid(maxWidth: 696, maxHeight: 464) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
                title
                text
              }
            }
          }
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;

export default ProcessesPage;
